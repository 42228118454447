import { LoadingComponent } from '@/app/components/loading/loading.component';
import { ModalColumnFilterComponent } from '@/app/components/modal-column-filter/modal-column-filter.component';
import { ToastComponent } from '@/app/components/toast/toast.component';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import * as XLSX from 'xlsx';
import { DatosCuaService } from '../../service/datosCua.service';

@Component({
  selector: 'app-ver-detalle-bono',
  templateUrl: './ver-detalle-bono.component.html',
})

export class VerDetalleBonoComponent implements OnInit{
  @ViewChild('toastContainer', { read: ViewContainerRef, static: true })
  toastContainer!: ViewContainerRef;

  payloadTabla: any = {
    tieneTabla: false,
    detalles: {
      columnas: [],
      filas: []
    }
  };

  selectedColumns: any[] = [];
  searchText: string = '';
  filteredColumns: any[] = [];
  cua: string= '';
  payload:any = [];
  idPagina:any = null;
  
  anio: string = '';
  detalleBono: any = [];
  ramo: any = [];
  componenteDetalle:any  = null;
  periodo: number = 0;
  periodoSufijo = '';
  tipoPeriodo: string = '';
  tipoPeriodoReturn: string = '';
  tipoPeriodoMostrar: string = '';
  periodoMes:any = null;
  cveRamo: string = '';
  cveSegmento: string = '';
  tipo: string = '';
  generacion: string = '';
  anioOriginal: string = '';
  colorTotal: string = '';
  corte : string = '';
  detalles:any = [];


  totalesObj:any = []; 
  logradoObj:any = [];
  faltanteObj:any = [];
  dataDecoded:any = [];
  esBonoComponenteDetalle: boolean = false;
  esBonoDetalle: boolean = false;
  esEntidad: boolean = false;
  dataTabla:any=[];
  rol:string = '';
  periodoMaximo: string = '';
  componente: any = [];

  constructor(
    private _location: Location,
    public serviceCua: DatosCuaService,
    public dialog: MatDialog,
    public route: Router,
    private cdRef: ChangeDetectorRef
  ) { 

    
  }

  ngOnInit() {
    this.cua = sessionStorage.getItem('cua')!;
    this.rol = sessionStorage.getItem('rol')!;

    this.detalleBono = JSON.parse(sessionStorage.getItem('bonos')!);
    if(history.state.dataDetalle.subComponente !== undefined && history.state.dataDetalle.subComponente?.length > 0 ){
      this.esBonoDetalle = true;
      this.detalles = history.state.dataDetalle.subComponente;
    }else if(history.state.dataDetalle.detalles !== undefined && history.state.dataDetalle.faltante > 0 ){
      history.state.dataDetalle.detalles.forEach((elem:any) => {
        if(elem.logrado !== '0'){
        this.esEntidad = true;
        this.detalles = history.state.dataDetalle.detalles;
        this.armarPantalla();
        }
        
      });

    }
    if(history.state.data){
      this.dataDecoded = history.state.data;
      this.ramo = history.state.data.ramo;
      this.periodo = history.state.data.periodo;
      this.periodoMaximo = history.state.data.periodo;
      this.periodoSufijo = history.state.data.periodoSufijo;
      this.tipoPeriodoMostrar = history.state.data.tipoPeriodoMostrar;
      this.periodoMes = history.state.data.periodoMes;
      this.corte = history.state.data.corte;
      this.cveRamo = history.state.data.ramo;
      this.cveSegmento = history.state.data.segmento;
      this.anio = history.state.data.anio;
      this.tipo = history.state.data.tipo;
      this.generacion = history.state.data.generacion;
      this.anioOriginal = history.state.data.anio;
    }
      this.tipoPeriodoReturn = this.tipoPeriodo;
      this.colorTotal = 'azul';
      this.dataTabla = history.state.dataBono;
      this.totalesObj = history.state.dataBono?.totales;
      this.componente = history.state.componente;

      this.cambiarSufijoPeriodo();


      this.payload = {
        idPagina: this.idPagina,
        idPadreRamo: this.ramo,
        color: this.color(this.ramo),
        detalles: this.detalles,
        logrado: this.logradoObj,
        faltante: this.faltanteObj,
        totales: this.totalesObj,
        totalesSeparadosPorId: history.state.dataBono?.totales,
        ramo: this.ramo,
        titulo: history.state.dataBonoA? history.state.dataBonoA?.nombre: history.state.dataDetalle?.bono ,
        tieneTabla: false
      };
      this.datosTable();
  }

  datosTable(){

    if (this.dataTabla && this.dataTabla.columnas && this.dataTabla.filas) {
      this.payloadTabla.tieneTabla = true;
      

      // Asignación de columnas
      this.payloadTabla.detalles.columnas = this.dataTabla.columnas.map((col: any) => ({
        label: col.label,
        idColumna: col.idColumna,
        selected: false
      }));
      console.log('Columnas mapeadas:', this.payloadTabla.detalles.columnas);

      // Asignación de filas
      this.payloadTabla.detalles.filas = this.dataTabla.filas.map((fila: any) => {
        let mappedFila: any = {};
          this.payloadTabla.detalles.columnas.forEach((columna: any) => {
            mappedFila[columna.idColumna] = fila[columna.idColumna]; 
          });
          return mappedFila;
      });
    } else {
      console.warn("No se encontraron datos en la respuesta");
      this.payloadTabla.tieneTabla = false;
    }
  }

  cambiarSufijoPeriodo() {

    if (this.tipoPeriodo === 'Cuatrimestre' || this.tipoPeriodo === 'cuatri') {
      this.tipoPeriodoMostrar = 'Cuatrimestre';
      this.periodoMes = 'Enero-Abril';
      if (this.periodo === 3) {
        this.periodoMes = 'Septiembre-Diciembre';
      }
    } else {
      this.tipoPeriodoMostrar = 'Trimestre';
      this.periodoMes = 'Enero-Marzo';
      if (this.periodo === 3) {
        this.periodoMes = 'Julio-Septiembre';
      }
    }
    if (this.periodo === 2) {
      this.periodoSufijo = 'do';
      if (
        this.tipoPeriodo === 'Cuatrimestre' ||
        this.tipoPeriodo === 'cuatri'
      ) {
        this.periodoMes = 'Mayo-Agosto';
      } else {
        this.tipoPeriodoMostrar = 'Trimestre';
        this.periodoMes = 'Abril-Junio';
      }
    }
    if (this.periodo === 4) {
      this.tipoPeriodoMostrar = 'Trimestre';
      this.periodoSufijo = 'to';
      this.periodoMes = 'Octubre-Diciembre';
    }
  }

  openColumnFilter(): void {
    const dialogRef = this.dialog.open(ModalColumnFilterComponent, {
      width: '600px',  // Especifica el ancho del modal
      height: 'auto',  // La altura será automática según el contenido
      maxHeight: '90vh',
      data: { columns: this.payloadTabla.detalles.columnas }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Resultado del modal:', result); 
  
        this.selectedColumns = result.filter((col: { selected: any }) => col.selected);
        console.log('Selected Columns (después de filtrar):', this.selectedColumns); 
  
        this.filteredColumns = this.selectedColumns.map(col => col.idColumna);
        console.log('Filtered Columns (usando idColumna):', this.filteredColumns); 

        this.cdRef.detectChanges();

      }else {
        console.log('El modal se cerró sin seleccionar columnas');
      }
    });
  }

  removeColumn(column: any): void {
    const index = this.selectedColumns.indexOf(column);
    if (index >= 0) {
      this.selectedColumns.splice(index, 1);
    }
  }


  public detalleResumen(componente:any) {
    let loadingCua = this.dialog.open(LoadingComponent);
    this.serviceCua
      .getResumenBono(
        this.cveSegmento,
        this.ramo,
        this.cua,
        sessionStorage.getItem('idBonoSeleccionado'),
        this.anio,
        this.periodo,
        this.rol  
      )
      .subscribe(
        (dataDet: any) => {
          if (!dataDet.message) {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
            let dataDetalle: any = dataDet.data;
            loadingCua.close();
            let loading = this.dialog.open(LoadingComponent);
            this.serviceCua.getDetalleBono(this.cveSegmento,
              this.ramo,
              this.cua,
              componente.idCampo,
              this.anio,
              this.periodo,
              this.rol).subscribe(
              (detBon: any) => {
                if (!detBon.message) {
                  let dataBono = detBon.data;
                  this.detalleBono = JSON.parse(sessionStorage.getItem('bonos')!);
                  if(dataDetalle.subComponente !== undefined && dataDetalle.subComponente?.length > 0 ){
                    this.esBonoDetalle = true;
                    this.detalles = dataDetalle.subComponente;
                  }else if(dataDetalle.detalles !== undefined && dataDetalle.detalles?.length > 0 ){
                    this.esEntidad = true;
                    this.detalles = dataDetalle.detalles;
                    this.armarPantalla();
                  }
                    this.dataDecoded = history.state.data;
                    this.ramo = history.state.data.ramo;
                    this.periodo = history.state.data.periodo;
                    this.periodoSufijo = history.state.data.periodoSufijo;
                    this.tipoPeriodoMostrar = history.state.data.tipoPeriodoMostrar;
                    this.periodoMes = history.state.data.periodoMes;
                    this.tipoPeriodo = history.state.data.tipoPeriodo;
                    this.tipoPeriodoReturn = this.tipoPeriodo;
                    this.corte = history.state.data.corte;
                    this.cveRamo = history.state.data.ramo;
                    this.cveSegmento = history.state.data.segmento;
                    this.anio = history.state.data.anio;
                    this.tipo = history.state.data.tipo;
                    this.generacion = history.state.data.generacion;
                    this.anioOriginal = history.state.data.anio;
                    this.colorTotal = 'azul';
                    this.dataTabla = dataBono;
                    this.totalesObj = dataBono?.totales;
                    this.route.navigate(['/ver-det-bono'], {
                      state: {
                        dataDetalle: dataDetalle,
                        dataBono: dataBono,
                      }
                    });
                 
                  

                  loading.close();
                } else {

                  loading.close();
                }
              },
              (error) => {
                this.showError(
                  'Error al cargar el servicio. Intenta de nuevo.',
                  'iziToast-icon fas fa-exclamation revealIn'
                );
                loadingCua.close();
              }
            );

            loadingCua.close();
          } else {
            this.showError(
              dataDet.message,
              'iziToast-icon fas fa-exclamation revealIn'
            );
            loadingCua.close();
          }
        },
        (error) => {
          this.showError(
            'Error al cargar el servicio. Intenta de nuevo.',
            'iziToast-icon fas fa-exclamation revealIn'
          );
          loadingCua.close();
        }
      );
  }

  showError(message: string, icon: string) {
    const componentRef = this.toastContainer.createComponent(ToastComponent);
    componentRef.instance.message = message;
    componentRef.instance.show = true;
    componentRef.instance.icon = icon;

    // Opcional: Ocultar el toast automáticamente después de unos segundos
    setTimeout(() => {
      this.toastContainer.clear();
    }, 5000); // El toast se cerrará después de 3 segundos
  }

  armarPantalla() {
    this.logradoObj =
    {
        titulo: history.state.dataDetalle.bono,
        icono: history.state.dataDetalle.icono,
        componentes: history.state.dataDetalle.detalles,
        bonoLogrado: {
            titulo: history.state.dataBono?.totales[0]?.nombre,
            eldato: this.convertirValor(history.state.dataBono?.totales[0]?.tipo, history.state.dataBono?.totales[0]?.valor)
        },
    };
    if (history.state.dataBono?.totales[1]) {
      this.logradoObj.bonoPosible = { titulo: history.state.dataBono?.totales[1]?.nombre,
                                            eldato: this.convertirValor(history.state.dataBono?.totales[1]?.tipo, history.state.dataBono?.totales[1]?.valor)
        };
    }
    var faltante:any = [];
    this.detalles.forEach((detalle:any) => {
       if (detalle.faltante !== null && detalle.faltante !== 'None') {
          faltante.push(detalle);
      }
      
    });
    this.faltanteObj =
    {
        titulo: 'Para lograr el bono proyectado te falta:',
        componentes: faltante
    };


}


  goBack(){
    this.route.navigate(['/home']);
  }

  public validarTabSub(ramoId:any, idPagina:any){
    return (ramoId.substring(0,2) === idPagina.substring(0,2)) ? 'activenaranja' : '';
  }


  public color(clave:any) {

    if (clave === null || clave === undefined){
        return 'naranja-oscuro';
    }
    if (clave.includes('PY')) {
        return 'rojo';
    }
    else if (clave.includes('LC')) {
        return 'rojo-oscuro';
    }
    else if (clave.includes('VIDA')) {
        return 'verde';
    }
    else if (clave.includes('DANOS')) {
        return 'morado';
    }
    else if (clave.includes('AUTOS')) {
        return 'naranja';
    }
    else if (clave.includes('SALUD')) {
        return 'azul';
    }
    return 'rojo';
  }

  public valoresBloque(valores:any) {
    var valor1;
    var valor2;
    var sufijo1;
    var sufijo2;
    if(valores[0] === undefined  || valores[0].sufijo === undefined){
        sufijo1='';
    } else {
        sufijo1 = valores[0].sufijo;
    }
    if(valores[1] === undefined || valores[1].sufijo === undefined){

        sufijo2='';
    } else {
        sufijo2= valores[1].sufijo;
    }

    if(valores.length > 1 ){
        valor1 = this.convertirValor(valores[0].tipoDato,valores[0].valorDato) +' '+ sufijo1;
        valor2= this.convertirValor(valores[1].tipoDato,valores[1].valorDato) +' ' + sufijo2;
        return valor1 + ' / ' + valor2;
    } else {
        valor1= this.convertirValor(valores[0].tipoDato,valores[0].valorDato) +' '+ sufijo1;
        return valor1;
    }
  }

  public convertirValor(tipoDato:any, valor:any) {
    if(valor === null){
        valor = 0;
    }
    if (tipoDato === 'texto'){
        return valor;
    }
    if (tipoDato === 'moneda') {

        var signonegativo = '';
        valor = parseFloat(valor);

        var numformateado = new Intl.NumberFormat('es-MX').format(valor.toFixed(2));

        if(Math.sign(Number(valor)) === -1)
        {
            numformateado = numformateado.replace('-','');
            signonegativo='-';
        }
        return signonegativo + '$' + numformateado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    if (tipoDato === 'porcentaje') {
        valor = parseFloat(valor);

        return this.truncar((valor * 100),4) + '%';
    }
    if (tipoDato === 'numero') {
        valor = parseFloat(valor);
        return new Intl.NumberFormat('es-MX').format(valor.toFixed(4));
    }
    return valor;
  }

  truncar(x:any, posiciones = 0) {
    var s = x.toString();
    var decimalLength = s.indexOf('.') + 1;
    var numStr = s.substr(0, decimalLength + posiciones);
    return Number(numStr);
  }

  componenteDatos(componente:any) {
    
    var valorLogrado;
    var valorFaltante;
    if(componente.faltante && componente.faltante !== 'None'){
        valorFaltante = this.convertirValor(componente.tipoDatoFaltante,componente.faltante);
        valorLogrado= this.convertirValor(componente.tipoDato,componente.logrado);
        return valorLogrado + ' / ' + valorFaltante;
    } else {
        valorLogrado= this.convertirValor(componente.tipoDato,componente.logrado);
        return valorLogrado;
    }
};

  public componenteBono(componente:any,resumen?:any) {
    
    var valorLogrado;
    var campo2;
    if(resumen === 'resumen'){
        return this.componenteDatos(componente);
    }
    if(componente.logrado && componente.campo2 !== ''){
        campo2 = this.convertirValor(componente.tipoDatoCampo2,componente.campo2);
        valorLogrado= this.convertirValor(componente.tipoDato,componente.logrado);
        return valorLogrado + ' / ' + campo2;
    } else {
        valorLogrado= this.convertirValor(componente.tipoDato,componente.logrado);
        return valorLogrado;
    }
};

  regresarMenu(){
    this._location.back();
  }

  exportar() {
    if (this.dataTabla.filas.length > 0) {
      // Crea un Blob a partir del contenido y lo convierte a una URL
      const blob = this.buildExcelContent(this.dataTabla.filas);
      const url = URL.createObjectURL(blob);

      // Crea un enlace y simula un clic para descargar el archivo
      let dia = new Date();
      const link = document.createElement('a');
      link.href = url;
      link.download = `extraccion__${this.payload.titulo}_${dia}.xlsx`;
      link.click();

      // Libera la URL del Blob después de la descarga
      URL.revokeObjectURL(url);
    } else {
      console.error('No hay datos para generar el archivo Excel.');
    }
  }

  buildExcelContent(data: any[]) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  }

  calcularPeriodo (ramo:any,anio:any) {
    var periodo = parseInt(ramo.periodo);
    var trimestre = Math.ceil(parseInt(moment().format("M")) / 3);
    var cuatrimestre = Math.ceil(parseInt(moment().format("M")) / 4);
    if (parseInt(anio) === parseInt(moment().format("YYYY"))) {
        if(ramo.tipoPeriodo === "cuatri" && periodo > cuatrimestre){
            periodo =  cuatrimestre
        }
        if(ramo.tipoPeriodo === "tri" && periodo > trimestre){
            periodo =  trimestre;
        }
    }
    return periodo;
  }
  public cambioPeriodo(periodo:any) {
    if (periodo > 0 && periodo <= Number(this.periodoMaximo)) {
      let loadingCua = this.dialog.open(LoadingComponent);
      this.serviceCua
        .getResumenBono(
          this.cveSegmento,
          this.ramo,
          this.cua,
          history.state.data.componente.idCampo,
          this.anio,
          periodo,
          this.rol
        )
        .subscribe(
          (response: any) => {
            if (response.data) {
              this.detalleBono = response.data;
              loadingCua.close();
              let loading = this.dialog.open(LoadingComponent);
              this.serviceCua.getDetalleBono(this.cveSegmento,
                this.ramo,
                this.cua,
                this.componente.idCampo,
                this.anio,
                this.periodo,
                this.rol).subscribe(
                (detBon: any) => {
                  if (!detBon.message) {
                    this.dataTabla = detBon.data;
                    this.detalles = this.detalleBono?.detalles;
                    this.periodo = periodo;
                    this.payload = {
                      idPagina: this.idPagina,
                      idPadreRamo: this.ramo,
                      color: this.color(this.ramo),
                      detalles: this.detalles,
                      logrado: this.logradoObj,
                      faltante: this.faltanteObj,
                      totales: this.totalesObj,
                      totalesSeparadosPorId: history.state.dataBono?.totales,
                      ramo: this.ramo,
                      titulo: history.state.dataBonoA? history.state.dataBonoA?.nombre: history.state.dataDetalle?.bono ,
                      tieneTabla: false
                    };
                    this.cambiarPeriodo();
                    this.cambiarSufijoPeriodo();
                    this.datosTable();
                    loading.close();
                  } else {
                    this.dataTabla = detBon.data;
                    this.detalles = this.detalleBono?.detalles;
                    this.periodo = periodo;
                    this.payload = {
                      idPagina: this.idPagina,
                      idPadreRamo: this.ramo,
                      color: this.color(this.ramo),
                      detalles: this.detalles,
                      logrado: this.logradoObj,
                      faltante: this.faltanteObj,
                      totales: this.totalesObj,
                      totalesSeparadosPorId: history.state.dataBono?.totales,
                      ramo: this.ramo,
                      titulo: history.state.dataBonoA? history.state.dataBonoA?.nombre: history.state.dataDetalle?.bono ,
                      tieneTabla: false
                    }
                    this.cambiarPeriodo();
                    this.cambiarSufijoPeriodo();
                    this.datosTable();
                    loading.close();
                  }
                },
                (error) => {
                  this.showError(
                    'Error al cargar el servicio. Intenta de nuevo.',
                    'iziToast-icon fas fa-exclamation revealIn'
                  );
                  loadingCua.close();
                }
              );

            } else {
              this.showError(
                response.message,
                'iziToast-icon fas fa-exclamation revealIn'
              );
              loadingCua.close();
            }
          },
          (error) => {
            this.showError(
              'Error al cargar el servicio. Intenta de nuevo.',
              'iziToast-icon fas fa-exclamation revealIn'
            );
            loadingCua.close();
          }
        );
    }else{
      {
        this.showError(
          'No hay información a mostrar.',
          'iziToast-icon fas fa-exclamation revealIn'
        );
      }
    }
  }

  cambiarPeriodo() {
    if (this.periodo === 1) {
      this.periodoSufijo = 'er'
    }
    if (this.periodo === 2) {
      this.periodoSufijo = 'do';
    }
    if (this.periodo === 3) {
      this.periodoSufijo = 'er';
    }
    if (this.periodo === 4) {
      this.periodoSufijo = 'to';
    }
  }
  
  convertirCorte(corte:any) {
    let fechForm;
    if(corte !== ''){
    var dividirFecha = corte.split('/', 3);
    fechForm = 'Corte al ' + parseInt(dividirFecha[0]) + '/' + dividirFecha[1] + '/' + parseInt(dividirFecha[2].slice(-2));
    }
    return fechForm
  } 

  public regresarBono () {
    this._location.back();

  }
}