import { LoadingComponent } from '@/app/components/loading/loading.component';
import { ToastComponent } from '@/app/components/toast/toast.component';
import { DatosCuaService } from '@/app/service/datosCua.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-intermediario',
  templateUrl: './login-intermediario.component.html',
  styles: [
    `
      h3 {
        font-weight: bold;
        margin-bottom: 20px;
      }

      .btn-primary {
        background-color: #ff6f00;
        border-color: #ff6f00;
      }
    `
  ]
})
export class LoginIntermediarioComponent {

  
  @ViewChild('toastContainer', { read: ViewContainerRef, static: true })
  toastContainer!: ViewContainerRef;

  form: FormGroup;
  hide = true;
  rol: string = '';
  idCua: string = '';

  constructor(
    private datosCuaService: DatosCuaService,
    private fb: FormBuilder,
    public route: Router,
    public dialog: MatDialog
  ) {
    this.form = this.fb.group({
      segundaContrasena: ['']
    });
  }

  ngOnInit(): void {
    this.idCua = sessionStorage.getItem('cua')!;
  }

  
  showError(message: string, icon: string) {
    const componentRef = this.toastContainer.createComponent(ToastComponent);
    componentRef.instance.message = message;
    componentRef.instance.show = true;
    componentRef.instance.icon = icon;

    // Opcional: Ocultar el toast automáticamente después de unos segundos
    setTimeout(() => {
      this.toastContainer.clear();
    }, 5000); // El toast se cerrará después de 3 segundos
  }

  onSubmit() {
    const cua = this.idCua.substring(0, 5);
    const password = this.form.get('segundaContrasena')?.value;
  
    if (!password || password.trim() === '') {
      this.showError('Por favor ingresa una contraseña.', 'iziToast-icon fas fa-exclamation revealIn');
      return;
    }
  
    let loadingPas = this.dialog.open(LoadingComponent);
    this.datosCuaService.postSegundoPwd(password, cua).subscribe(
      (response) => {
        console.log('Respuesta del servicio postSegundoPwd:', response);
        if (response.bcaBonosSegundoPwdReturn.user.msg === false) {
          loadingPas.close();
          this.showError('Contraseña incorrecta. Intenta de nuevo.', 'iziToast-icon fas fa-exclamation revealIn');
        } else {
          console.log('Contraseña correcta. Acceso permitido.');
          let loadingPasB = this.dialog.open(LoadingComponent);
          
        
          this.datosCuaService.getBonos(this.idCua).subscribe(
            (bonosResponse) => {
              console.log('Respuesta del servicio getBonos:', bonosResponse);
              this.rol = bonosResponse.data.rol;
              sessionStorage.setItem('rol', bonosResponse.data.rol);
              loadingPasB.close();
  
              let dia = new Date();
              let anio = dia.getFullYear();
              this.datosCuaService.getRamosSegmentos(this.idCua, anio, this.rol).subscribe(
                (dataSeg: any) => {
                  if (!dataSeg.message) {
                    this.route.navigate(['/segmentos'], { state: { data: dataSeg, anio: anio } });
                  } else {
                    this.showError(dataSeg.message, 'iziToast-icon fas fa-exclamation revealIn');
                  }
                  loadingPas.close();
                },
                (error) => {
                  this.showError('Error al cargar el servicio. Intenta de nuevo.', 'iziToast-icon fas fa-exclamation revealIn');
                  loadingPas.close();
                }
              );
            },
            (error: HttpErrorResponse) => {
              this.showError('Error al obtener los datos de bonos. Intenta de nuevo.', 'iziToast-icon fas fa-exclamation revealIn');
              loadingPasB.close();
            }
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.showError('Error al validar la contraseña. Por favor, intenta de nuevo.', 'iziToast-icon fas fa-exclamation revealIn');
        loadingPas.close();
      }
    );
  }
}
