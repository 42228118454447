//@ANGULAR CORE
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { COMMA, SPACE } from '@angular/cdk/keycodes';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AppRoutingModule } from './app-routing.module';
import { LoadingComponent } from './components/loading/loading.component';
import { MaterialModule } from './material-module';


//Componentes
import { AppComponent } from './app.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { ToastComponent } from './components/toast/toast.component';
import { HomeComponent } from './view/home/home.component';
import { LoginIntermediarioComponent } from './view/login-intermediario/login-intermediario.component';
import { RamoComponent } from './view/ramo/ramo.component';
import { SegmentosComponent } from './view/segmentos/segmentos.component';
import { VerDetalleBonoComponent } from './view/ver-detalle-bono/ver-detalle-bono.component';
import { VerDetalleComponent } from './view/ver-detalle/ver-detalle.component';

//directivas
import { OnlyNumberLetterDirective } from './directives/only-number-letter.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';

//Servicios
import { ModalColumnFilterComponent } from './components/modal-column-filter/modal-column-filter.component';
import { FilterPipe } from './pipes/filter.pipe';
import { DatosCuaService } from './service/datosCua.service';
import { TokenInterceptorService } from './service/interceptor/token-interceptor.service';
import { JwtDecodeService } from './service/jwtDecodeService.service';



registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SegmentosComponent,
    RamoComponent,
    VerDetalleComponent,
    LoadingComponent,
    OnlyNumberLetterDirective,
    OnlyNumberDirective,
    LoginIntermediarioComponent,
    ToastComponent,
    DynamicTableComponent,
    ModalColumnFilterComponent,
    FilterPipe,
    VerDetalleBonoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' }
    },
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [COMMA, SPACE]
      }
    },
    DatosCuaService,
    JwtDecodeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
