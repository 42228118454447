<section class="row sin-margen" *ngIf="tieneTabla">
  <div class="col-md-12">
    <table id="tabladataproxy" class="table tabladetalle" data-page-length="10">
      <thead>
        <tr>
          <th scope="col" *ngFor="let th of columnas">{{ th.label }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fila of paginatedRows">
          <td *ngFor="let columna of columnas">{{ fila[columna.idColumna] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <section class="row sin-margen">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <div style="color:#00337f" class="pagination-label">
        {{ getPaginationLabel() }}
      </div>
      <nav>
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="changePage(currentPage - 1)">Anterior</a>
          </li>
          <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index"
            [class.active]="currentPage === (i + 1)">
            <a class="page-link" (click)="changePage(i + 1)">{{ i + 1 }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="changePage(currentPage + 1)">Siguiente</a>
          </li>
        </ul>
      </nav>
    </div>
  </section>
</section>

<section class="row sin-margen" *ngIf="!tieneTabla">
  <div class="col-md-12 text-center titulo1">
    No hay información a mostrar
  </div>
</section>