import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  
  transform(items: any[], searchText: string, filteredColumns: string[], columns: any[]): any[] {
    if (!items) {
      console.log('No hay items para filtrar.');
      return [];
    }

    if (!searchText || !filteredColumns || filteredColumns.length === 0) {
      console.log('No se ha ingresado texto de búsqueda o no hay columnas filtradas.');
      return items;
    }

    console.log('Items recibidos para filtrar:', items);
    console.log('Texto de búsqueda:', searchText);
    console.log('Filtered Columns:', filteredColumns);

    const filteredItems = items.filter(item => {
      return filteredColumns.some(col => {
        const cellValue = item[col]?.toString()?.toLowerCase() || '';
        console.log(`Valor de la celda para la columna "${col}":`, cellValue); // Verifica qué valor está buscando
        return cellValue.includes(searchText.toLowerCase());
      });
    });

    console.log('Items filtrados:', filteredItems);
    return filteredItems;
  }
}
