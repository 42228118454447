<div #toastContainer></div>
<div class="container-fluid">
  <div class="row">
    <div class="container">
      <div class="col-md-2 offset-md-5">
        <div class="row">
          <div class="col-sm-3 col-md-3">
            <button class="btn-icono btn-flat" id="btn-anio-izquierda">
              <mat-icon class="chevron_color" (click)="cambioAnio(anio - 1)">chevron_left</mat-icon>
            </button>
          </div>
          <div class="col-sm-6 col-md-6 div-titulo-anio">
            <span class="titulo-anio">{{ anio }}</span>
          </div>
          <div class="col-sm-3 col-md-3">
            <button class="btn-icono btn-flat" id="btn-anio-derecha">
              <mat-icon class="chevron_color" (click)="cambioAnio(anio + 1)">chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row text-center justify-content-center">
    <div class="col-md-3">
      <span class="titulo-secundario">{{ trimestre }} {{ sufijoTri }} Trimestre / 4</span>
    </div>
    <div class="col-md-3">
      <span class="titulo-secundario">{{ cuatrimestre }} {{ sufijoCuatri }} Cuatrimestre / 3</span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4">
          <div class="icono-y-texto">
            <mat-icon>account_box</mat-icon> <span class="eltexto">CUA</span>
            <span class="negritass">{{ cua }}</span>
          </div>
        </div>
        <div class="col-md-5">&nbsp;</div>
        <div class="col-md-3 space-cua">
          <span class="unlink" *ngIf="_ban_portalEntrada=='intermediario' ?  false : true" (click)="goBack()">
            Cambiar CUA
          </span>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="xflex-fill align-self-start col-md-6 col-sm-6 Xsin-padding" *ngFor="let arreglo of arregloSegmentos">
      <div class="titulo-Segmento">{{ arreglo.segmento }}</div>

      <div class="tarjeta-borde-color {{ color(ramo.cveRamo[0]) }}" *ngFor="let ramo of arreglo.ramos">
        <div class="row align-items-center marg-left">
          <div class="col-md-8 col-sm-8 xpadding-0-10">
            <div class="titulo">{{ ramo.ramo }}</div>
            <span class="eltexto {{ arreglo.esEntidad ? 'negritas azul' : '' }}" *ngIf="!arreglo.esEntidad">{{
              convertirCorteMenu(ramo.corte) }}</span>
            <span class="eltexto {{ arreglo.esEntidad ? 'negritas azul' : '' }}" *ngIf="arreglo.esEntidad">{{ ramo.corte
              }}</span>
          </div>
          <div class="col-md-4 col-sm-4 padding-0-10">
            <div class="detalles text-center" (click)="verDetalles(ramo)" *ngIf="
                !arreglo.esEntidad && ramo.cveSubSegmento != ramo.cveSegmento
              ">
              Ver detalles
            </div>

            <div class="detalles text-center" (click)="verDetalles(ramo)" *ngIf="
                !arreglo.esEntidad && ramo.cveSubSegmento == ramo.cveSegmento
              ">
              Ver detalles
            </div>

            <div class="detalles text-center" (click)="verEntidad(ramo.id, arreglo.segmento)" *ngIf="arreglo.esEntidad">
              Ver detalles
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>