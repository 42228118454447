import { environment } from '@/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { Constants } from '../utils/Constants';

const LPTA2_COOKIE_NAME = 'LtpaToken2';
const JWT_COOKIE_NAME = 'jwt-bonos';
const ID_PART_COOKIE_NAME = 'idpart';

const BONOADMIN = 'BONOSADMINISTRADOR';

const DELAY_MILLIS = 1500;

const rolesCatalog: string[] = [BONOADMIN];

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _validSource = new Subject<any>();
  private _validSession = {
    isValid: false,
    delayMillis: 0
  };

  public validSession$ = this._validSource.asObservable();

  set validSession(params: any) {
    this._validSession = {
      ...this._validSession,
      ...params
    };
    this._validSource.next(this._validSession);
  }

  get validSession() {
    return this._validSession;
  }

  constructor(
    private readonly cookieSrv: CookieService,
    private readonly http: HttpClient
  ) {
    this._validSession = {
      isValid: this.validSessionByJwt(),
      delayMillis: 0
    };
  }

  public isValid(): boolean {
    return this._validSession.isValid;
  }

  public validSessionByJwt(idParticipante: any = null): boolean {
    let idpart = idParticipante;
    if (!idpart && this.cookieSrv.check(ID_PART_COOKIE_NAME)) {
      idpart = this.cookieSrv.get(ID_PART_COOKIE_NAME);
    }

    if (this.cookieSrv.check(JWT_COOKIE_NAME) && idpart) {
      let user: any = jwtDecode(this.cookieSrv.get(JWT_COOKIE_NAME));
      if (
        (environment.jwtValidation && idpart == user.idparticipante) ||
        !environment.jwtValidation
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public auth(): void {
    const idpart = sessionStorage.getItem('idparticipante');
    if (this.validSessionByJwt(idpart)) {
      this.validSession = {
        isValid: true,
        delayMillis: DELAY_MILLIS
      };
    } else {
      this.getCookie(idpart);
    }
  }

  private getCookie(idpart: any) {
    if (this.cookieSrv.check(LPTA2_COOKIE_NAME)) {
      this.getToken().subscribe({
        next: (r: any) => {
          let domain = '.gnp.com.mx';
          if (window.location.hostname === 'localhost') {
            domain = 'localhost';
          }
          let today = moment().add(1, 'hours').toDate();
          this.cookieSrv.set(
            JWT_COOKIE_NAME,
            r.access_token,
            today,
            '/',
            domain
          );
          this.cookieSrv.set(ID_PART_COOKIE_NAME, idpart, today, '/', domain);
          this.cookieSrv.set(Constants.ACCESS_TOKEN_COOKIE, r.access_token, today, '/', domain);
          this.cookieSrv.set(Constants.REFRESH_TOKEN_COOKIE, r.refresh_token, today, '/', domain);

          let user: any = jwtDecode(r.access_token);
          if (
            (environment.jwtValidation && idpart == user.idparticipante) ||
            !environment.jwtValidation
          ) {
            this.validSession = {
              isValid: true,
              delayMillis: 0
            };
          } else {
            this.validSession = {
              isValid: false
            };
          }
        },
        error: () => {
          this.validSession = {
            isValid: false
          };
          this.clearToken();
        }
      });
    } else {
      this.validSession = {
        isValid: false
      };
      this.clearToken();
    }
  }

  clearToken() {
    this.cookieSrv.delete(Constants.ACCESS_TOKEN_COOKIE);
    this.cookieSrv.delete(Constants.REFRESH_TOKEN_COOKIE);
  }

  getToken(): Observable<any> {
    //Se obtiene el ltpaToken que genera el portal de intermediarios
    let ltpaToken = this.cookieSrv.get(LPTA2_COOKIE_NAME);

    //Para la libreria OAuth se le hace encode b64 a el clientId y clientSecret para pasarlo como Basic Header
    let tokenB64 = btoa(`${environment.clientIdOAuth}:${environment.clientSecretOAuth}`)

    const headers = new HttpHeaders({
      Authorization: `Basic ${tokenB64}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const body = new URLSearchParams();
    body.append(
      'grant_type',
      'urn:ietf:params:oauth:grant-type:token-exchange'
    );
    body.append('subject_token', ltpaToken);
    body.append(
      'subject_token_type',
      'urn:ietf:params:oauth:token-type:ltpatoken2'
    );
    body.append('requested_token_type', 'urn:ietf:params:oauth:token-type:jwt');

    return this.http.post<any>(
      `${environment.urlServApiGee}${Constants.PATH_OAUTH_TOKEN}`,
      body.toString(),
      { headers }
    );
  }

  getRefreshToken(): Observable<any> {
    //Se obtiene el RefreshToken que genera el metodo getToken y lo guarda en una cookie 
    let refreshTokenOAuth = this.cookieSrv.get(Constants.REFRESH_TOKEN_COOKIE);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const body = new URLSearchParams();
    body.append('client_id', `${environment.clientIdOAuth}`);
    body.append('grant_type', Constants.REFRESH_TOKEN);
    body.append('client_secret', `${environment.clientSecretOAuth}`);
    body.append('refresh_token', refreshTokenOAuth);

    return this.http.post<any>(
      `${environment.urlServApiGee}${Constants.PATH_OAUTH_TOKEN}`,
      body.toString(),
      { headers }
    );
  }
}
