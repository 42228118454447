import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss'
})
export class DynamicTableComponent implements OnChanges{
  @Input() columnas: { idColumna: string, label: string, orden: number, tipoDato: string }[] = [];
  @Input() filas: any[] = [];
  @Input() tieneTabla: boolean = true;

  // Paginación
  currentPage: number = 1;
  itemsPerPage: number = 10; // aqui se especifica el numero de elementos
  paginatedRows: any[] = [];
  totalPages: number = 0;



  ngOnInit() {
    this.calculatePagination();
    this.columnas = this.columnas.sort((a, b) => a.orden - b.orden);
  }

  

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filas']) {
      this.calculatePagination();  // Recalcula la paginación al cambiar las filas
    }
    if (changes['columnas']) {
      this.columnas = this.columnas.sort((a, b) => a.orden - b.orden);  
    }
  }

  constructor(private cdRef: ChangeDetectorRef) {}



  calculatePagination() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedRows = this.filas.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.filas.length / this.itemsPerPage);
  }

  changePage(page: number) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.calculatePagination();
  }

  getPaginationLabel(): string {
    const startRecord = (this.currentPage - 1) * this.itemsPerPage + 1;
    const endRecord = Math.min(startRecord + this.itemsPerPage - 1, this.filas.length);
    return `Mostrando ${this.currentPage} - ${endRecord} de ${this.filas.length} registros`;
  }
}
