import { Constants } from '@/app/utils/Constants';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  public decodedToken: any = '';
  public jwt: string = '';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private readonly cookieSrv: CookieService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.cookieSrv.check(Constants.ACCESS_TOKEN_COOKIE)) {
      let jwt = this.cookieSrv.get(Constants.ACCESS_TOKEN_COOKIE);
      const authReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${jwt}`)
      });

      return next.handle(authReq).pipe(
        catchError((error) => {
          if (error.status === 401) {
            return this.authService.getRefreshToken().pipe(
              switchMap((newJwt) => {

                 let domain = '.gnp.com.mx';
                  if (window.location.hostname === 'localhost') {
                    domain = 'localhost';
                  }
                  let today = moment().add(1, 'hours').toDate();
                   this.cookieSrv.set(Constants.ACCESS_TOKEN_COOKIE, newJwt.access_token, today, '/', domain);
                   this.cookieSrv.set(Constants.REFRESH_TOKEN_COOKIE, newJwt.refresh_token, today, '/', domain);
                const refreshedReq = request.clone({
                  headers: request.headers
                    .set('Authorization', `Bearer ${newJwt.access_token}`)
                    .set('Content-Type', 'application/json')
                });

                return next.handle(refreshedReq);
              }),
              catchError((refreshTokenError) => {
                this.authService.clearToken();
                return throwError(() => refreshTokenError);
              })
            );
          } else {
            return throwError(() => error);
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }

  LoginSesion<T>() {
    this.router.navigate(['/auth']);
  }

  ObtieneLtpaToken() {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        const params = new URLSearchParams(fragment.split('?')[1]);

        const portal = params.get('portal');
        const idpart = params.get('idpart');
        const cua = params.get('cua');

        console.log('Portal:', portal);
        console.log('IDPart:', idpart);
        console.log('CUA:', cua);
        sessionStorage.setItem('idparticipante', idpart!);
        sessionStorage.setItem('tipousuario', portal!);
        sessionStorage.setItem('cua', cua!);
        this.LoginSesion();
      }
    });
  }
}
